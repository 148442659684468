<template>
  <v-dialog
    width="800"
    v-model="dialog"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="mr-3 elevation-0 rounded-lg"
        color="primary"
        x-small
        fab>
        <v-icon>
          mdi-plus
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        Nuevo archivo
      </v-card-title>

      <v-card-text>
        <v-file-input
          solo
          outlined
          flat
          v-model="attributes.file"
          label="Seleccione archivo"
          class="rounded-lg ml-3"
          ></v-file-input>

        <div
          class="text-right"
          >
          <v-btn
            color="primary"
            class="rounded-lg"
            depressed
            @click="createFolder"
            >
            Crear
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { CreateFile } from '@/graphql/mutations/repository'

export default {
  data: () => ({
    dialog: false,
    attributes: {
      file: null,
      siteId: null,
    }
  }),

  props: {
    folderId: {
      type: Number | String,
      required: true
    },

    siteId: {
      type: Number | String,
      required: false
    },

    sites: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    }
  },
  
  watch: {
    siteId () {
      this.attributes.siteId = this.siteId
    }
  },

  created () {
    this.attributes.siteId = this.siteId
  },

  methods: {
    createFolder () {
      this.$apollo.mutate({
        mutation: CreateFile,
        variables: {
          input: {
            attributes: {
              ...this.attributes,
              folderId: this.folderId
            }
          }
        }
      }).then ( res => {
        this.$emit('reload')
        this.name = null
        this.dialog = false
      })
    },
  }
}
</script>
